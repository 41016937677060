

/* Estilos generales para escritorio */
.redes-container {
  background-color: #1e3d75;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  width: 300px;
  margin: 0 0 20px 0;
}

.redes-container h5 {
  color: white;
  font-size: 16px;
  margin-bottom: 15px;
  text-align: start;
}

.nav-rsociales {
  display: flex;
  justify-content: center; 
  align-items: center;
  padding: 0 0 20px 0;
  list-style: none; 
  margin: 0; 
}

.nav-rsociales li {
  margin: 0 20px;
}

.nav-rsociales a {
  color: white;
  font-size: 40px;
  text-decoration: none;
}

.redesociales {
  background-color: #ffffff;
  padding: 8px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  color: #1e3d75;
}

.redesociales:hover {
  background-color: #007bff; 
  color: white;
}

.redes-canal {
  background-color: #1e3d75;
  padding: 20px;
  border-radius: 15px;
  text-align: center;
  width: 300px;
  height: 175px;
  margin: 20px auto;
}

.canal {
  background-image: url('canal.png');
  background-size: cover;
  background-position: center;
}

.afiliacion {
  background-image: url('afiliateahora.png');
  background-size: cover;
  background-position: center;
}

.avi {
  width: 100%;
  grid-area: laterales;
  overflow: hidden;
  margin-bottom: 20px;
}

.notlat {
  height: 450px;
  width: 100%;
  margin-bottom: 20px;
  margin-left: 0;
  margin-top: 5%;
  text-align: center;
}

.notlat img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  margin-bottom: 10px;
  border-radius: 10px;
}




/* Estilos para la galería en móviles */
@media (max-width: 768px) {
  .redes-container {
    background-color: #1e3d75;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    width: 300px;
    margin: 20px 0 20px 0;
    height: 150px;
  }
  .redes-container h5{
    margin-top: 10px;
  }
  .nav-rsociales{
    margin-top: 20px;
  }
 
  
  .mobile-slider {
    overflow-x: auto;
    white-space: nowrap;
    padding: 20px;
    display: flex;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
  }

  .mobile-slider::-webkit-scrollbar {
    display: none; /* Ocultar scrollbar en navegadores Webkit */
  }

  .slider-wrapper {
    display: inline-flex;
    gap: 20px; /* Añade espacio entre los elementos */
  }

  .redes-container, .redes-canal, .notlat {
    width: 230px; /* Ajusta el ancho de las tarjetas en móviles */
    flex-shrink: 0; /* Evita que las tarjetas se encogen */
  }

  .redes-canal {
    height: 150px; /* Ajusta la altura de las tarjetas */
    background-size: cover;
    background-position: center;
    border-radius: 15px;
  }

  .notlat img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .redesociales {
    font-size: 35px;
    padding: 5px;
  }

  .avi {
    display: inline-block;
    width: 100%;
  }

  .imgAviso{
    margin-top: 20px;
  }

  .notlat {
    height: 150px;
    width: 230px;
    margin-right: 20px;
  }
} 