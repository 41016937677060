.footer-container {
    background-color: #1a3b6b; /* Color de fondo azul */
    color: white;
    padding: 20px 0 0 0;
  }
  
  .footer-links {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .footer-link {
    color: white;
    margin: 0 15px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  
  /*
   .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 20px;
    border-top: 2px solid #00aaff;
  }
   
  */

  .footer-content{
    width: 70%;
    margin: 0 auto;

  }

  /* .footer-section {
    min-width: 250px;
    margin: 10px 0;
    background-color: aqua;
  } */
  .footer-section {
    min-width: 250px;
    margin: 30px 0;
    width: 950px;
    margin-left: -250px;
  }
 


  .footer-section h4 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
  }
  
  .footer-section p {
    font-size: 14px;
    margin: 5px 0;
    display: inline;

    margin-left: 10px;
  }
  
  .footer-section a {
    
    text-decoration: none;
    color: white;
    font-weight: bold;
  }
  
 
  
  .footer-bottom {
    margin-bottom: 0px;    text-align: center;
    margin-top: 20px;
    padding-top: 20px;
    color: white;
    background-color: #1C3361;
    height: 60px;
  }
  
  .footer-top-line {
    background-color: #266DA5; /* Línea de color azul */
    height: 3px;
    width: 70%;
    margin-bottom: 10px;
    margin: 0 auto;

  }
  .footer-logo-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    margin-left: -150px;
    margin-top: 80px;
  }
.footer-logo{
  margin-top: -50px;
}

.footer-logo-container2 {
  display: flex;
  flex-direction: column; /* Alinea los elementos en columna */
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 150px;
  margin-top: 20px;
  margin-right: -100px;
}

  /* .footer-logo{
    width: 110px;
    height: 90px;
    margin: 0 auto;
  }
  .logo1{
background-color: white;
margin-left: -150px;
} */


.directioncont{
  width: 350px;

}

.footer-logo-second{
  width: 110px;
  padding: 10px;
}






@media (max-width: 768px) {
  .logo1,
  .footer-logo-container2 {
    display: none; /* Oculta los logos en dispositivos móviles */
  }

  .footer-section {
    width: 100%; /* Hace que ocupe el 100% del ancho disponible */
    max-width: 100%; /* Asegura que no exceda el ancho del contenedor */
    font-size: 12px; /* Reduce el tamaño de la fuente para pantallas pequeñas */
    box-sizing: border-box; /* Asegura que los padding y bordes se incluyan dentro del ancho */
    margin: 0 auto; /* Centra el contenido */
    padding: 5px; /* Agrega un pequeño padding para que no se vea pegado a los bordes */
    margin-bottom: 15px;
  }
}
