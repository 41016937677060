
.install-button {
    background-color: #1e3d75;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .install-button .cancel-btn {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .install-button:hover {
    background-color: #375b9e;
  }
  
  .logoinstall{
    width: 80%;
  }


  .cancel-btn{
    background-color: #e81410;

  }

  .cancel-btn:hover{
    background-color: #e84e4b;
  }


  .apptitlemodal{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    padding-bottom: 5px;
    border-bottom: #1e3d75 2px solid;
  }

  .modal-content{
    box-shadow: -13px 10px 22px 4px rgba(76,72,72,0.55);

}