.divider{
    background-color: #0B8EC9;
    color: white;
    margin-bottom: 50px;
   
}

.divider h3{
    font-size: 20px;
    margin-left: 50px;
    padding: 13px 0;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: capitalize;
}

.actual{
    background-color: #0B8EC9;
    color: white;
}



.contentimg {
    position: relative;
    overflow: hidden;
    border-radius: 20px;

  }

  .contentimg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s;
    z-index: 0; /* Colocar detrás del degradado */
  }


  .contentimg:hover img {
    transform: scale(1.2);
  }
  
  .etiqueta-recomendados {
    position: absolute;
    display: flex; /* Utilizamos flexbox para alinear el ícono y el texto */
    align-items: center; /* Alinea verticalmente */
    bottom: 15px;
   
    color: white;
    background-color: #3c90ea;
    height: 30px;
    font-family: Rubik;
    padding: 0 10px; /* Ajusta el espaciado interno */
    z-index: 1;
  }
  
  /* Ajusta el tamaño y el color del ícono si es necesario */
  .etiqueta-recomendados i {
    font-size: 18px;
    margin-right: 5px;
  }
  
.finale-fav{
  font-weight: lighter;
  font-size: 25px;

}

.card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.conetenedor-cards {
  animation-name: fadeIn;
  animation-duration: 1s;
}