/* En tu archivo CSS o en línea en tu componente */
.green-button {
    background-color: #4caf50; /* Cambia esto al color verde que desees */
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
  }
  