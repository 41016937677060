
.fabv {
    position: absolute;
    top: -4px;
    right: -35px;
    background-color: #3b82f6; /* Azul similar a Tailwind 'blue-500' */
    color: white;
    width: 100px; /* Tamaño del contenedor */
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(45deg); /* Rotación de 45 grados */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
