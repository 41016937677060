.contenedortop5 {
    gap: 20px;
    display: grid;
    width: 1200px;
    margin-left: 10%;
    height: 529px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .cont1 {
    border-radius: 20px;
    height: 529px;
    position: relative;
    overflow: hidden;
  }
  
  .cont1::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.907) 0%, transparent 100%);

    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    border-radius: 20px;
    z-index: 1; /* Colocar por encima de la imagen */
  }
  
  .cont1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s;
    z-index: 0; /* Colocar detrás del degradado */
  }
  
  .cont1:hover img {
    transform: scale(1.2);
  }
  
  .cont2 {
    height: 529px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "avsuperior avsuperior"
      "infder iniz";
  }
  
  .avsuperior {
    grid-area: avsuperior;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
  }

  .avsuperior:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.907) 0%, transparent 100%);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    border-radius: 20px;
    z-index: 1; /* Colocar por encima de la imagen */
  }



  .avsuperior img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s;
    z-index: 0; /* Colocar detrás del degradado */
  }
  
  .avsuperior:hover img {
    transform: scale(1.2);
  }




  
  .infder {
    grid-area: infder;
    background-color: rgb(0, 255, 30);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
  }
  .infder:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.907) 0%, transparent 100%);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    border-radius: 20px;
    z-index: 1; /* Colocar por encima de la imagen */
  }



  .infder img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s;
    z-index: 0; /* Colocar detrás del degradado */
  }
  
  .infder:hover img {
    transform: scale(1.2);
  }


  
  .iniz {
    grid-area: iniz;
    background-color: brown;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
  }
  
  .iniz:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.907) 0%, transparent 100%);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    border-radius: 20px;
    z-index: 1; /* Colocar por encima de la imagen */
  }

  .iniz img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s;
    z-index: 0; /* Colocar detrás del degradado */
  }
  
  .iniz:hover img {
    transform: scale(1.2);
  }


  
  .etiqueta-img,
  .title-noticia,
  .descripcion-notas {
    position: absolute;
    z-index: 1; /* Asegura que el contenido de texto esté en la parte superior */
  }
  
  .etiqueta-img {
    margin-top: 55%;
    top: 10px;
    left: 10px;
    color: white;
    background-color: #d6293e;
    height: 30px;
    width: 120px;
    font-family: Rubik;
    padding-left: 12px;
    padding-top: 3px
  }
  
  .etiqueta-img i {
    font-size: 10px;
  }
  
  .title-noticia {
    top: 73%;
    left: 2%;
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  
  .descripcion-notas {
    top: 80%;
    left: 2%;
    
    color: white;
    text-align: left;
    font-size: 16px;
  }

  .avsuperior img,
  .infder img,
  .iniz img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cambia "cover" por "contain" */
  }





  .etiqueta-imgsuperior {
 
    top: 50%;
    left: 10px;
    color: black;
    background-color: #f7c32e;
    height: 30px;
    width: 120px;
    font-family: Rubik;
    padding-left: 12px;

    padding-top: 3px
  }
  
  .etiqueta-imgsuperior i {
    font-size: 10px;
  }


  .title-noticia-superior {
    top: 65%;
    left: 2%;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    text-decoration: none; /* Elimina el subrayado predeterminado */
  position: relative;
  }




  .title-noticia-derecha {
    top: 65%;
    left: 5%;
    color: white;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    text-decoration: none; 
    position: relative;
}



  .title-noticia-derecha  {
    position: absolute;
    z-index: 1; 
  }

  
  .title-noticia-derecha::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px; /* Altura del subrayado */
    bottom: 0;
    left: 0;
    background-color: white; /* Color del subrayado */
    transition: width 1s ease-in-out; /* Duración y efecto de la transición */
    z-index: 1;
  }
  
  .title-noticia-derecha:hover::before {
    width: 100%; /* Ancho completo al hacer hover */
  }

 





  .title-noticia-izquierda {
    top: 65%;
    left: 5%;
    color: white;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    text-decoration: none; 
  position: relative;
  }

  .title-noticia-izquierda  {
    position: absolute;
    z-index: 1; 
  }

 .title-noticia-izquierda::before {
    content: "";
    position: absolute;
    width: 0; 
    height: 2px; 
    bottom: 0;
    left: 0;
    background-color: white; 
    transition: width 1s ease-in-out; 
    z-index: 1;
  } 

  .title-noticia-izquierda:hover::before {
    width: 100%; /* Ancho completo al hacer hover */
  }

  


 
  .title-noticia-superior::before,
  .title-noticia::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px; /* Altura del subrayado */
    bottom: 0;
    left: 0;
    background-color: white; /* Color del subrayado */
    transition: width 1s ease-in-out; /* Duración y efecto de la transición */
  }


  
  .title-noticia-superior:hover::before,
  .title-noticia:hover::before {
    width: 100%; /* Ancho completo al hacer hover */
  }


  
  .descripcion-notas-superior {
    top: 78%;
    left: 2%;
    text-align: left;
    color: white;
    font-size: 14px;
  }



    
  .etiqueta-imgsuperior,
  .title-noticia-superior,
  .descripcion-notas-superior,
  .etiqueta-infder, 
  .etiqueta-infiz {
    position: absolute;
    z-index: 1; 
  }





  .etiqueta-infder {
 
    top: 50%;
    left: 10px;
    color: white;
    background-color: #14bf5e;
    height: 30px;
    width: 120px;
    font-family: Rubik;
    padding-left: 12px;
    padding-top: 3px

  }

  .etiqueta-infiz {
 
    top: 50%;
    left: 10px;
    color: white;
    background-color: #3c90ea;
    height: 30px;
    width: 120px;
    padding-left: 12px;

    font-family: Rubik;
    padding-top: 3px
  }




/* Estilos para la galería en móviles */
@media (max-width: 768px) {
  .contenedortop5 {
    width: 100%; /* Ajusta el ancho en pantallas pequeñas */
    grid-template-columns: 1fr; /* Cambia la disposición a una sola columna */
  }

  .cont1, .cont2 {
    height: auto; /* Ajusta la altura para pantallas pequeñas */
  }

  .contbtn span {
    font-size: 12px; /* Ajusta el tamaño de la fuente */
    white-space: normal; /* Permite saltos de línea en el texto */
  }

  .etiqueta-img {
    margin-top: -50px;
    color: white;
    background-color: #d6293e;
    height: 30px;
    width: 120px;
    font-family: Rubik;
    padding-left: 12px;
    margin-bottom: 30px;
    border-radius: 5px;
    position: relative; /* Asegura que siempre esté en su lugar */
    
    z-index: 1;
  }

  .corona {
    position: absolute;
    top: 15px;
    right: 18px;
    background-color: #0d6efd;
    color: white;
    z-index: 1;
    font-size: 12px;
    border-radius: 5px; /* Opcional: Redondear bordes */
    padding: 5px 8px; 
    border-radius: 20px;
    animation: latido 1.5s infinite; /* Agrega la animación de latido */
  }

  

  
  @keyframes latido {
    0%, 100% {
      transform: scale(1); /* Tamaño original */
    }
    50% {
      transform: scale(1.2); /* Tamaño aumentado en el punto medio */
    }
  }
    

  .mobile-slider {
    overflow-x: auto;
    white-space: nowrap;
    padding: 20px;
    display: flex;
    scrollbar-width: none; 
    -ms-overflow-style: none;
  }
  .mobile-slider::-webkit-scrollbar {
    display: none; /* Ocultar scrollbar en navegadores Webkit */
  }

  .slider-wrapper {
    display: inline-flex;
    gap: 10px;
  }

  .slide-item {
    width: 310px;
    margin: 10px auto;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: -10px 10px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative; /* Añade posición relativa para los íconos */
    height: 550px;
  }


  .slide-item img {
    width: 290px;
    height: 450px;
    object-fit: cover;
    border-radius: 5px;
  }

  .slide-item h2 {
    font-size: 16px;
    color: #333;
    margin-top: 10px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    font-weight: bold;
  }
}
