.container-comunicados{
    width: 92%;
    margin-left: 4%;
    margin-bottom: 80px;
}

.descripcion-txt{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}

.img-r{
    border-radius: 15px;
}

.titulo{
    color: #212529 !important;
    text-transform: capitalize;
    margin-top: 10px;
}

.row.divcom {
    overflow-x: hidden;
    margin: 0 auto;
  }
  