.btn-nav {
  color: #1e3d75;
  font-weight: bold;
  text-decoration: none;
  padding-bottom: 5px;
  font-size: 16px;
  margin-left: 120px;
  position: relative;
  z-index: 1001;
}

.btn-nav-compact {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  padding-bottom: 5px;
  font-size: 14px;
  margin-left: 20px;
  position: relative;
  z-index: 1001;
  transition: color 0.3s ease;
}

.containerbtnsnav {
  margin-left: 280px;
  margin-bottom: 15px;
}

.btn-nav::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: #cdd0d3;
  transform: scaleX(1.05);
  transform-origin: center;
  transition: transform 0.4s ease-in-out;
}

.btn-nav:hover::after {
  transform: scaleX(1.15);
  background-color: #1e3d75;
}

.nav-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  transition: height 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
}

.nav-container.scrolled {
  background-color: #1e3d75;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url('../../assets/fondo.svg') center/cover no-repeat;
  width: 100%;
  height: 280px;
  z-index: 2;
  transition: height 0.3s ease;
}

.nav-content.scrolled {
  background-color: #1e3d75;
  height: 80px;
}

.nav-logo img {
  height: 70px;
  margin: -80px 0 0 130px;
  width: auto;
  transition: height 0.3s ease;
}

.nav-content.scrolled .nav-logo img {
  height: 40px;
  margin-top: 0;
}

.nav-social-search {
  display: flex;
  align-items: center;
  margin-right: 20px;
  z-index: 999;
}

.nav-below {
  color: white;
  padding: 10px 0;
  text-align: center;
  font-size: 14px;
  margin-top: -80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: padding 0.3s ease, font-size 0.3s ease;
}

.nav-below.scrolled {
  padding: 5px 0;
  font-size: 12px;
  margin-top: 0;
}

.nav-links {
  margin-left: 80px;
  width: auto;
  white-space: nowrap;
}

.nav-links ul {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  z-index: 1001;
}

.nav-links ul li {
  margin: 0 20px;
}

.user-icon-container {
  margin-top: -120px;
}

.user-icon {
  background-color: #fff;
  color: #1e3d75;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 40px;
  margin-left: 25px;
  margin-right: 140px;
  position: relative;
  z-index: 11;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.user-icon-minimo {
  color: white;
  cursor: pointer;
  font-size: 25px;
  margin-left: 25px;
  margin-right: 90px;
  margin-top: 40px;
  position: relative;
  z-index: 11;
  padding: 4px;
  transition: transform 0.3s ease, border 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.user-icon-minimo:hover {
  border-radius: 50%;
  border: 2px solid white;
  transform: scale(1.5);
  color: white;
}

.user-icon:hover {
  background-color: #1e3d75;
  color: #fff;
  border: 2px solid white;
}

.search-container-alt {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-top: 40px;
}

.search-input-alt {
  padding: 5px;
  border: 1px solid #ccc;
  outline: none;
  width: 150px;
  height: 30px;
  border-radius: 20px;
  padding-left: 15px;
}

.search-input {
  padding: 5px 10px;
  border: none !important;
  outline: none;
  margin-right: 20px;
  width: 0;
  color: #1e3d75;
  z-index: 3;
  padding-right: 30px;
  text-indent: 20px;
  transition: width 0.5s ease, visibility 0.5s ease;
  visibility: hidden;
  background-color: #fcfcfc00 !important;
}

.search-container {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #ccc;
  padding: 4px 10px;
  cursor: pointer;
  width: 40px;
  transition: width 0.5s ease;
  background-color: white;
  z-index: 2;
  margin-top: -120px;
}

.search-container:hover,
.search-container:focus-within,
.search-container.active {
  width: 250px;
  cursor: text;
}

.search-container:hover .search-input,
.search-container:focus-within .search-input,
.search-container.active .search-input {
  width: 200px;
  visibility: visible;
}

.clear-search-btn {
  position: absolute;
  right: 23px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: #314c9d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  z-index: 10;
}

.clear-search-btn .clear-icon {
  font-size: 1rem;
}

.clear-search-btn:hover {
  color: #000;
}

.search-icon {
  position: absolute;
  left: 10px;
  font-size: 1rem;
  color: #314c9d;
  z-index: 5;
  background-color: none !important;
}

.main-content {
  margin-top: 320px;
}

.nav-container.scrolled .nav-content {
  background: none;
  background-color: #1e3d75;
  height: 20px;
}

.nav-logo-alt img {
  width: 150px;
  margin: 50px 40px 10px 120px;
}


.mobile-menu-button {
  display: none;
  cursor: pointer;
}

.mobile-menu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  background-color: #1e3d75;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transform: translateX(100%);
  transition: transform 0.3s ease;
}

.mobile-menu.open {
  display: block;
  transform: translateX(0);
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
}

.mobile-menu ul li {
  margin-bottom: 20px;
}

.mobile-menu ul li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
}

.user-icon-mobile {
  font-size: 24px;
  color: white;
  cursor: pointer;
}

/* Mostrar el botón del menú en pantallas móviles */
@media (max-width: 768px) {
  .mobile-menu-button {
    display: block;
  }

  .nav-below,
  .nav-links,
  .search-container,
  .search-container-alt,
  .user-icon-container {
    display: none;
  }
}
