.carousel-container {
  position: relative;
  width: 100%;
  margin-top: 30px;
  cursor: pointer;
}

.carousel-wrapper {
  position: relative;
  height: 56vh;
  overflow: hidden;
  border-radius: 0.5rem;
}

.carousel-slide {
  display: none;
}

.carousel-slide.block {
  display: block;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.3s ease;
}

.carousel-container.blur-bg .carousel-image {
  filter: blur(5px) grayscale(100%);
}

.carousel-indicators {
  position: absolute;
  bottom: 1.25rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.75rem;
}

.indicator-button {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: #ccc;
}

.carousel-prev,
.carousel-next {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  cursor: pointer;
  z-index: 10;
}

.carousel-prev {
  left: 0;
}

.carousel-next {
  right: 0;
}

.modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.907); /* Fondo oscuro para el modal */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.modal img {
  max-width: 80%;
  max-height: 90%;
  width: auto;
  height: auto;
  object-fit: contain;
  background: none; /* Asegura que no haya ningún fondo detrás de la imagen */
  box-shadow: none; /* Elimina cualquier sombra que pueda estar detrás de la imagen */
}

.modal-prev,
.modal-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  font-size: 2rem;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px;
}

.modal-prev {
  left: 10px;
}

.modal-next {
  right: 10px;
}

.btnclose {
  position: absolute;
  top: -50px; /* Aumenta la distancia desde la parte superior */
  right: -20px;
  font-size: 55px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  z-index: 1001;
}

@media (max-width: 768px) {
  .modal-prev, .modal-next {
    font-size: 1.5rem; /* Tamaño más pequeño en móviles */
    padding: 5px;
    z-index: 1;
  }

  .btnclose {
    top: -65px; /* Ajusta la posición del botón de cerrar en móviles */
    right: 15px;
    font-size: 45px; /* Tamaño más pequeño del botón cerrar */
  }
}
