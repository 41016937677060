.tooltip {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.icon-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.tret{
  position: absolute;
  z-index: 99;
  background: var(--bs-primary);
  color: var(--bs-white);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  text-align: center;
  top: -8px;
  right: -48px;
  width: 120px;
  padding: 16px 0 3px 0;
}


.relative {
  overflow: hidden;

}

/* AlojamientoComponent.css */
.main-content.blur-bg {
  filter: blur(5px) grayscale(100%);
  transition: filter 0.3s ease;
}






/* Responsive tweaks */
.main-content.blur-bg {
  filter: blur(5px) grayscale(100%);
  transition: filter 0.3s ease;
}

.tret {
  position: absolute;
  z-index: 99;
  background: var(--bs-primary);
  color: var(--bs-white);
  transform: rotate(45deg);
  text-align: center;
  top: -8px;
  right: -48px;
  width: 120px;
  padding: 16px 0 3px 0;
}

.relative {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: white;
  font-size: 30px;
  cursor: pointer;
}

.fabv {
  position: absolute;
  top: -4px;
  right: -35px;
  background-color: #3b82f6; /* Azul similar a Tailwind 'blue-500' */
  color: white;
  width: 100px; /* Tamaño del contenedor */
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg); /* Rotación de 45 grados */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.divimg{

  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}




/* 
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal img {
  max-width: 80%;
  max-height: 80%;
  border-radius: 10px;
}

.modal-prev, .modal-next {
  position: absolute;
  top: 50%;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px;
}

.modal-prev {
  left: 10px;
}

.modal-next {
  right: 10px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: white;
  font-size: 30px;
  cursor: pointer;
} */


