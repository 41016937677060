.mobile-bottom-bar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: sticky;
    bottom: 0;
    background-color: #1e3d75;
    padding: 10px;
    height: 60px;
    width: 100%;
    z-index: 1000;
  }
  
  .mobile-bottom-link {
    display: flex;
    flex-direction: column; /* Pone el ícono encima del texto */
    align-items: center; /* Centra verticalmente */
    justify-content: center; /* Centra horizontalmente */
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 15px; 
  }

  .ises{
    font-size: 10px; /* Tamaño de letra más pequeño */

  }
  
  .mobile-bottom-link .icon {
    font-size: 18px; /* Tamaño del ícono */
    margin-bottom: 4px; /* Añade espacio entre el ícono y el texto */
  }
  
  .mobile-bottom-link:hover {
    color: #cdd0d3; /* Cambia el color al hacer hover */
  }
  
  /* Solo visible en dispositivos móviles */
  @media (min-width: 768px) {
    .mobile-bottom-bar {
      display: none;
    }
  }
  