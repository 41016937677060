.section-anuncios {
    display: grid;
    grid-template-columns: auto 285px; /* Deja que la primera columna se ajuste automáticamente y establece un ancho fijo para la segunda columna */
    grid-template-areas: "anuncios laterales";
    gap: 25px; /* Añade un espacio de 5px entre las columnas */
    margin-top: 30px;
}

@media (max-width: 768px) {
    .section-anuncios {
        grid-template-columns: auto;
        grid-template-areas: 
            "anuncios"
            "laterales";
        gap: 0; /* Elimina el espacio entre columnas en pantallas pequeñas */
    }
}

.titulo-seccion-anuncios {
    text-align: start;
    margin-left: 50px;
    margin-top: 60px;
}

.parrafo-seccion-anuncios {
    text-align: start;
    margin-left: 50px;
}

/* Añadir margin-top a los resultados de búsqueda */
.search-results {
    margin-top: 350px; 
}
