.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
  margin: 15px;
  font-size: 20px;
  border: 2px solid #2163e8;
  padding: 10px;
  border-radius: 50%;
  color: #2163e8;
  background-color: #f6f8fb;
  text-decoration: none; /* Asegura que no haya subrayado */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}
/* 
.contenedor_iconos {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
} */

.contenedor_iconos {
  height: 150px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto; /* Habilita el scroll horizontal */
  white-space: nowrap; /* Evita que los elementos se apilen verticalmente */
  scrollbar-width: thin; /* Personaliza el ancho del scrollbar en Firefox */
  -ms-overflow-style: none; /* Quita el scrollbar en IE y Edge */
}

.contenedor_iconos::-webkit-scrollbar {
  display: none; /* Oculta el scrollbar en navegadores Webkit como Chrome */
}


.material-symbols-outlined.active {
  background-color: #c6ceee;
  color: #102f6e;
}


.contbtn {
  display: inline-block;
  margin-right: 20px; /* Espaciado entre los iconos */
  text-align: center;
}



/* 
.contbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
} */

.contbtn span {
  font-size: 12px;
  width: 100px;
  text-align: center;
}

.navbar {
  margin-bottom: 20px;
margin-top: 300px;
}

@media (max-width: 768px) {
  .navbar {
    margin-bottom: 20px;
    margin-top: 0;
  }

  .contenedor_iconos {
    flex-direction: row;
    overflow-x: auto; /* Mantiene el scroll horizontal en pantallas móviles */
    padding: 15px;
    white-space: nowrap; /* Para que no se rompa la fila */
  }

  .contbtn {
    width: 80px; /* Ajusta el ancho del contenedor para pantallas móviles */
    display: flex; /* Usa flexbox para alinear el contenido */
    flex-direction: column; /* Alinea icono y texto verticalmente */
    justify-content: center; /* Centra el contenido verticalmente */
    align-items: center; /* Centra el contenido horizontalmente */
    text-align: center; /* Centra el texto */
  }
  
  .contbtn span {
    font-size: 12px;
    display: block;
    white-space: normal; /* Permite el salto de línea */
    line-height: 1.2; /* Ajusta el espaciado entre las líneas de texto */
    text-align: center; /* Centra el texto horizontalmente */
  }
  
  .material-symbols-outlined {
    font-size: 30px; /* Ajusta el tamaño del icono en pantallas más pequeñas */
    width: 48px;  /* Hace que el icono sea cuadrado */
    height: 48px; /* Hace que el icono sea cuadrado */
    line-height: 30px; /* Centra verticalmente */
    text-align: center;
  }
}



/* Remueve el subrayado de cualquier enlace dentro de los íconos */
.material-symbols-outlined a {
  text-decoration: none;
}

/* Opcional: Agrega un hover para mejorar la interactividad */
.material-symbols-outlined:hover {
  background-color: #e0e6f8;
  color: #102f6e;
  border-color: #102f6e;
}
