/* ModalLogin.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
  }
  
  .modal-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .modal-button {
    width: 100%;
    padding: 10px;
    background-color: #1e3d75;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-button:hover {
    background-color: #314c9d;
  }
  
  .modal-forgot {
    margin-top: 10px;
  }
  
  .modal-forgot a {
    color: #1e3d75;
    text-decoration: none;
  }
  
  .modal-close {
    margin-top: 15px;
    background: none;
    border: none;
    color: #1e3d75;
    cursor: pointer;
  }
  