.nav-mobile-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1e3d75;
    padding: 10px;
    height: 60px;
    position: sticky;
    top: 0;
    z-index: 1000;
    transition: transform 0.3s ease; /* Transición suave al aparecer/desaparecer */
  }
  
  .nav-mobile-container.hidden {
    transform: translateY(-100%); /* Mueve el navbar fuera de la pantalla */
  }
  
  .nav-mobile-container.visible {
    transform: translateY(0); /* Hace que el navbar sea visible */
  }
  
  .nav-logo-mobile img {
    height: 40px;
  }
  
  .search-container-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    position: relative;
  }
  
  .search-input-mobile {
    padding: 5px 40px 5px 15px;
    border: 1px solid #ccc;
    width: 80%;
    height: 30px;
    border-radius: 20px;
  }
  
  .search-icon-mobile {
    font-size: 1rem;
    color: #fff;
    margin-right: 10px;
  }
  
  .clear-search-btn-mobile {
    background-color: #1e3d75;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 1rem;
    color: #fff;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  