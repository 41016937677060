.custom-container {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.card-img {
  width: 100%;
  height: auto;
  border-radius: 20px;
  transition: transform 0.5s ease-in-out;
  object-fit: cover;
}

.card-anuncios {
  border: none;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}

.card-anuncios:hover .card-img {
  transform: scale(1.2);
}

.col-12.col-sm-6.col-md-4 {
  max-width: 33%;
  flex: 0 0 33%;
}

@media (max-width: 768px) {
  .col-12.col-sm-6.col-md-4 {
    max-width: 48%;
    flex: 0 0 48%;
  }
}

@media (max-width: 576px) {
  .col-12.col-sm-6.col-md-4 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.conetenedor-cards {
  padding: 0 15px;
}

.card-body .card-title {
  font-family: Rubik;
}

.card-body .card-text {
  font-family: Rubik;
  font-weight: 100;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.txtcard {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.subtitle_line {
  position: relative;
  text-decoration: none;
  color: #595d69;
  font-family: Rubik;
  font-weight: 400;
  font-size: 15px;
  padding-bottom: 2px;
}

.subtitle_line::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #595d69;
  transition: width 0.3s ease-in-out;
}

.subtitle_line:hover::before {
  width: 100%;
}

.actions-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.favorito-corazon {
  color: red;
  cursor: pointer;
}

.etiqueta-recomendados {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(255, 215, 0, 0.8);
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
}


